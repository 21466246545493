export default {
  // 获取个人信息
  getRoleSwitchInfo: "/api/v1/getRoleSwitchInfo",
  // 用户保存修改信息
  sysUser: "/api/v1system/sysUser",
  // 用户修改密码
  updatePassword: "/api/v1/updatePassword",
  // 解绑openId
  unBind: "/api/v1/laCustomers/unBind",

};
