export default {
  /**
   * 接口名文件文档
   * 例:
   * // 获取图形验证码
   * captchaImage:'captchaImage',
   */
  getUserMenus: "/api/v1/system/sysMenu/getUserRoleMenu",
  saveCommonMenu: "/api/v1/saveCommonMenu",
  userFastMenus: "/api/v1/getCommonMenu",
};
