import { createRouter, createWebHistory, useRouter } from "vue-router";
let Menu = JSON.parse(localStorage.getItem("Mens"));
// routes 导航组
const routes = [
  // tabbar导航菜单
  {
    path: "/",
    name: "index",
    meta: {
      title: "6S员工查分平台",
    },
    component: () => import("@/views/apps/tabbar/index.vue"),
  },
  // 工作流页面
  {
    path: "/workflow",
    name: "workflow",
    component: () => import("@/views/apps/indexList/workflow.vue"),
  },
  // 资料编辑
  {
    path: "/workflowEmit",
    name: "workflowEmit",
    component: () => import("@/views/apps/indexList/workflowEmit.vue"),
  },
  // 个人信息页
  {
    path: "/me",
    name: "me",
    meta: {
      title: "个人中心",
    },
    component: () => import("@/views/apps/tabbar/me.vue"),
  },
  // 个人详细信息
  {
    path: "/meinfo",
    name: "meinfo",
    meta: {
      title: "个人信息",
    },
    component: () => import("@/views/apps/me/meinfo.vue"),
  },
  // 菜单列表
  {
    path: "/Menu",
    name: "Menu",
    component: () => import("@/views/apps/MenuManagement/Menu.vue"),
  },
  // 修改密码
  {
    path: "/modificationUseronfo",
    name: "modificationUseronfo",
    meta: {
      title: "修改密码",
    },
    component: () => import("@/views/apps/me/modificationUseronfo.vue"),
  },
  // pdf
  {
    path: "/pdf",
    name: "pdf",
    meta: {
      title: "pdf预览",
    },
    component: () => import("@/components/componentPreview/pdf.vue"),
  },
  // 资讯发布
  {
    path: "/issueList",
    name: "issueList",
    meta: {
      title: "咨讯发布",
    },
    component: () => import("@/views/apps/indexList/issueList.vue"),
  },
  // 资讯审核
  {
    path: "/consultingService",
    name: "consultingService",
    meta: {
      title: "咨讯审核",
    },
    component: () => import("@/views/apps/indexList/consultingService.vue"),
  },
  // 二级菜单列表
  {
    path: "/secondLeveIndex",
    name: "secondLeveIndex",
    meta: {
      title: "咨讯审核",
    },
    component: () => import("@/views/apps/secondLevel/secondLeveIndex.vue"),
  },
  // 班级每日管理添加
  {
    path: "/daysgroupsAdd",
    name: "daysgroupsAdd",
    meta: {
      title: "班级管理添加",
    },
    component: () => import("@/views/apps/staffaSsess/daysgroupsAdd.vue"),
  },
  // 班级管理收购
  {
    path: "/daysgroupsPurchase",
    name: "daysgroupsPurchase",
    meta: {
      title: "班级管理收购",
    },
    component: () => import("@/views/apps/staffaSsess/daysgroupsPurchase.vue"),
  },
  {
    path: "/daysgroupsBasicComponent",
    name: "daysgroupsBasicComponent",
    meta: {
      title: "班级管理基分",
    },
    component: () =>
      import("@/views/apps/staffaSsess/daysgroupsBasicComponent.vue"),
  },
  // 基分添加页面
  {
    path: "/daysgroupsBasicComponentAdd",
    name: "daysgroupsBasicComponentAdd",
    meta: {
      title: "班级管理基分添加",
    },
    component: () =>
      import("@/views/apps/staffaSsess/daysgroupsBasicComponentAdd.vue"),
  },
  // 年
  {
    path: "/year",
    name: "year",
    meta: {
      title: "年分值查询",
    },
    component: () => import("@/views/apps/scor/year.vue"),
  },
  // 月
  {
    path: "/month",
    name: "month",
    meta: {
      title: "月分值查询",
    },
    component: () => import("@/views/apps/scor/month.vue"),
  },
  // 日
  {
    path: "/day",
    name: "day",
    meta: {
      title: "日分值查询",
    },
    component: () => import("@/views/apps/scor/day.vue"),
  },
  // 基础数据列表
  // {
  //   path: "/basedata/department",
  //   name: "basedataDepartment",
  //   meta: {
  //     title: "",
  //   },
  //   component: () => import("@/views/apps/"),
  // },
];
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
});
const recursion = function (data, id) {
  if (data) {
    let result;
    if (!data) {
      return;
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      let object = {
        path: item.path, // 这里要把父路由的路径也带上
        name: item.menuCode,
        meta: { title: item.menuName },
        component: () => import(`@/views/apps${item.path}`),
      };
      router.addRoute(object);
      // console.log("路由列表", router.getRoutes(), routes);
      if (item.children && item.children.length > 0) {
        result = recursion(item.children);
      }
    }
    return result;
  }
};
console.log(router);
// 递归处理菜单
recursion(Menu);
//路由守卫
router.beforeEach((to, from, next) => {
  console.log(to, from);
  document.title = to.meta.title;
  if (!sessionStorage.getItem("token")) {
    if (to.name == "index") {
      sessionStorage.setItem("token", to.query.token);
    }
  }
  next();
  NProgress.start(); //进度条
});

export default router;
