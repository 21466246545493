// import { createApp } from "vue";
import { createStore } from "vuex";
// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      count: 0,
      token: null,
      Menus: [],
      userInfo: ""
    };
  },
  mutations: {
    increment(state, k) {
      state.count = k;
    },
    getuser(state, userInfo) {
      state.userInfo = userInfo;
    },
    // vuex储存token
    stockpile(state, token) {
      state.token = token;
    },
    // vuex储存项目菜单信息
    Menus(state, Menus) {
      state.Menus = Menus;
    },
  },
  actions: {
    // Menu菜单列表信息
    MenuList({ commit }, data) {
      commit("Menus", data);
    },
  },
});
export default store;
// const app = createApp();
// app.config.globalProperties.$store = store;
// // // 将 store 实例作为插件安装
// // app.use(store);
