export default {
  // 获取资讯管理栏目管理信息
  getRoleSwitchInfo: "/api/v1/system/sysNavigator/sub",
  // 获取选择队的所有列表
  getLaCompanysList: "/api/v1/laCompanys/list",
  // 获取班级名称列表
  getLaGroupslist: "/api/v1/laGroups/list",
  // 获取执行班次列表
  getGroupFlight: "/api/v1/laGroups/getGroupFlight",
  // 获取岗位模式列表
};
