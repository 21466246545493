import axios from "axios";
import { baseURL } from "@/config/net.config";
import $store from "@/store";
import $route from "@/router";
import { useRoute, useRouter } from "vue-router";
// 判断请求地址超时
// axios接口封装
// 判断测试接口和正式接口
let instance = axios.create({
  baseURL,
  headers: {
    "content-type": "application/json;",
    // "xx-Token": $store.state.token ? $store.state.token : sessionStorage.getItem('token'),
    "xx-Token": "APP:5b64204555c77f55d5267f35d1b7e417",
    "access-control-allow-headers":
      "x-requested-with,Access-Token, content-type, accept, origin, X-Toon-User-ID, X-Toon-User-Token, X-Toon-User-Agent, ocmUserToken, userAccessToken",
  },
});

// 接口超时判断
// axios.defaults.timeout = 5000
// 路由拦截可判断token是否过期
// 请求拦截器
// 发出请求时拦截
// var token = sessionStorage.getItem('token')
const token = "";
let KeyStr = "";
instance.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem("token") == undefined) {
      token = $store.state.token
        ? $store.state.token
        : $route.currentRoute.value.query.token;
    } else {
      if ($route.currentRoute.value.query.token) {
        $store.commit("stockpile", $route.currentRoute.value.query.token);
        sessionStorage.setItem("token", $route.currentRoute.value.query.token);
      }
    }

    if ($route.currentRoute.value.query.KeyStr) {
      localStorage.setItem("KeyStr", $route.currentRoute.value.query.KeyStr);
    }
    config.headers = {
      "content-type": "application/json;",
      Authorization: sessionStorage.getItem("token"),
      "xx-token": sessionStorage.getItem("token"),
      "access-control-allow-headers":
        "x-requested-with,Access-Token, content-type, accept, origin, X-Toon-User-ID, X-Toon-User-Token, X-Toon-User-Agent, ocmUserToken, userAccessToken",
      "xx-publicKey": localStorage.getItem("KeyStr").replace(/\s/g, "+"),
    };
    return config;
  },
  (err) => {
    return Promise.reject(err); //返回错误
  }
);

// 响应拦截器
// 接口响应时拦截
instance.interceptors.response.use(
  (res) => {
    if (res.data.code == 401) {
      // 用于微信小程序 / web端方法
      uni.reLaunch({
        url: "/pages/index/index",
      });
      // app跳转方法
      return res;
    }
    return res;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err); //返回错误
  }
);
// const service = axios.create({
//         baseURL:this.$C,
//         timeout:5000     //访问超时的时间限制axios
//     })
// 到处请求示例
export default instance;
