import { createApp } from "vue";
import router from "@/router";
import store from "@/store";

// api使用
import api from "@/api/model/index.js";
import App from "@/App.vue";
import http from "@/utils/request.js";
import "default-passive-events";
// 接口模块使用
import indexApi from "@/config/index/indexApi";
// Menu菜单模板接口
import MenuApi from "@/config/index/MenuApi";
// 个人中心模板接口使用
import MeApi from "@/config/index/meApi";
// 资讯管理模板接口使用
import consultiveManagementApi from "@/config/index/consultiveManagementApi";
// 通用管理接口使用
import generalApi from "@/config/index/generalApi";
// 通用员工统计接口使用
import scor from "@/config/index/scor";
// 部门信息页面管理
import DepartmentInformationApi from "@/config/index/DepartmentInformationApi";
// 班级管理列表
import laDaysGroupsApi from "@/config/index/laDaysGroupsApi";
// 图片上传
import img from "@/config/index/image";
import "vant/lib/index.css";
// import login from "@/config/projectApi/login.js";
// app.use(store);
// V3挂载全局
let wx = require("weixin-js-sdk");
import VueWechatTitle from "vue-wechat-title"; //动态修改title
const app = createApp(App);

// import VConsole from "vconsole";

// const vConsole = new VConsole();
// // vant 引入使用
import {
  Button,
  Cell,
  CellGroup,
  NavBar,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Badge,
  Icon,
  Toast,
  Empty,
  Dialog,
  Uploader,
  ActionSheet,
  Field,
  DatetimePicker,
  ImagePreview,
  Popup,
  Image as VanImage,
  Switch,
  picker,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem,
  Search,
  List,
  Stepper,
  PullRefresh,
  Divider,
  Skeleton,
} from "vant";
// // 方式一. 通过 app.use 注册
// // 注册完成后，在模板中通过 <van-button> 或 <VanButton> 标签来使用按钮组件
app
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(NavBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(Grid)
  .use(GridItem)
  .use(Icon)
  .use(Badge)
  .use(Toast)
  .use(Empty)
  .use(Dialog)
  .use(Uploader)
  .use(ActionSheet)
  .use(Field)
  .use(ImagePreview)
  .use(VanImage)
  .use(Popup)
  .use(Switch)
  .use(picker)
  .use(RadioGroup)
  .use(Radio)
  .use(Collapse)
  .use(CollapseItem)
  .use(Search)
  .use(List)
  .use(PullRefresh)
  .use(Stepper)
  .use(Divider)
  .use(DatetimePicker)
  .use(Skeleton);

app.use(router);
app.use(VueWechatTitle);
//挂载全局使用接口axios
app.config.globalProperties.$http = http;
// 挂载全局使用router
app.config.globalProperties.$router = router;
// 挂载全局使用store
app.config.globalProperties.$store = store;
// 挂载全局使用axios封装方法
app.config.globalProperties.$Api = api;
// 接口模块挂载使用
app.config.globalProperties.$indexApi = indexApi;
app.config.globalProperties.$MenuApi = MenuApi;
app.config.globalProperties.$MeApi = MeApi;
app.config.globalProperties.$consultiveManagementApi = consultiveManagementApi;
app.config.globalProperties.$generalApi = generalApi;
app.config.globalProperties.$scor = scor;

app.config.globalProperties.$DepartmentInformationApi =
  DepartmentInformationApi;
app.config.globalProperties.$img = img;
// 班级每日管理
app.config.globalProperties.$laDaysGroupsApi = laDaysGroupsApi;
// 微信返回信息使用
app.config.globalProperties.$wx = wx;
// 图片接口

// axios全局挂载
// app.config.globalProperties.$http = axios;
// router路由全局挂载
// app.config.globalProperties.$router = router
// app.component(Skeleton.name, Skeleton); // 注册骨架屏组件
app.mount("#app");
