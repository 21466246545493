export default {
  /**
   * 接口名文件文档
   * 例:
   * // 获取图形验证码
   * captchaImage:'captchaImage',
   */
  // 单位列表（分页）
  sysDepartPage: "/api/v1/system/sysDepart/page",
  // 单位列表（不分页）
  sysDeartlist: "/api/v1/system/sysDepart/list",
};
