import http from "@/utils/http.js";
// import http from './api/js/request.js'
export default {
  get: async function (url, params) {
    return await http.get(url, params);
  },
  post: async function (url, data) {
    return await http.post(url, data);
  },
  put: async function (url, data) {
    return await http.put(url, data);
  },
  delete: async function (url, data) {
    return await http.delete(url, data);
  },
};
