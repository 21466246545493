export default {
  /**
   * 接口名文件文档
   * 例:
   * // 获取图形验证码
   * captchaImage:'captchaImage',
   */
  // 字典配置接口
  getAllData: "/api/v1/system/sysDictionary/getAllData",
  // 配置接口
  CgetAllData: "/api/v1/sysConfig/getAllData",
  putSysNavigator: "/api/v1/system/sysNavigator",
  // 资讯发布接口
  getPage: "/api/v1/system/sysInformation/page",
  // 资讯发布
  getNavigatorUnique: "/api/v1/system/sysInformation/navigatorUnique",
  //
  putSaveAndPublish: "/api/v1/system/sysInformation/saveAndPublish",
  // 资讯审核
  getUserInformation: "/api/v1/system/sysInformation/getUserInformation",
  // 获取菜单信息
  getUserRoleMenu: "/api/v1/system/sysMenu/getUserRoleMenu",
  // 获取菜单信息
  getOpenId: "/api/v1/weiChat/getOpenId",
  // 
  getAllDatas: "/api/v1/sysConfig/getAllData",

};
