<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { ref, getCurrentInstance, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "App",
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRoute();
    const token = ref();
    setTimeout(() => {
      // console.log(router.query.userInfo, "村村");
      token.value = router.query.token;
      sessionStorage.setItem("token", token.value);
      sessionStorage.setItem("KeyStr", router.query.KeyStr);
      localStorage.setItem("token", router.query.token);
      localStorage.setItem("userInfo", router.query.userInfo);
      localStorage.setItem("KeyStr", router.query.KeyStr);
      proxy.$store.commit("stockpile", token.value);
      // console.log(useRouteone.query.userInfo);
    }, 100);
    watch(token, (newValue, oldValue) => {
      if (newValue) {
        if (newValue != null) {
          proxy.$store.commit("stockpile", newValue);
        }
        sessionStorage.setItem("token", newValue);
        return {
          token,
        };
      }
    });
    return {
      token,
      router,
    };
  },
};
</script>

<style>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  font-size: 0.28rem;
}

/* body,
html {
  height: 100%;
} */

.flx {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flx1 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flx2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flx3 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.flx4 {
  display: flex;
  align-self: flex-end;
}

.flx5 {
  display: flex;
  align-self: flex-start;
}

.flx6 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flx7 {
  display: flex;
  justify-content: flex-end;
}

.flx8 {
  display: flex;
  align-items: flex-end;
}

.qingfu:after {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

.centers {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#app {
  width: 100%;
  background: #f4f4f4;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.box {
  min-height: 11rem;
  padding-top: 2.5rem;
  background: #f4f4f4;
  padding-bottom: 1rem;
}
</style>
