export default {
  /**
   * 接口名文件文档
   * 例:
   * // 获取图形验证码
   * captchaImage:'captchaImage',
   */
  GetLaDaysGroupsPage: "/api/v1/laDaysGroups/page",
  // 提交添加接口
  postLaDaysGroups: "/api/v1/laDaysGroups",
  // 收购数据回显
  getlaDaysBuyspage: "/api/v1/laDaysBuys/page",
  // 添加材料列表
  getlaDaysMaterialspage: "/api/v1/laDaysMaterials/page",
  // 工作量提交
  PutlaDaysBuys: "/api/v1/laDaysBuys",
  // 获取材料
  getLaMaterialsList: "/api/v1/laMaterials/list",
  // 材料提交
  PostlaDaysMaterial: "/api/v1/laDaysMaterials",
  // 获取基分列表
  laDaysCustomersList: "/api/v1/laDaysCustomers/list",
  // 获取班级员工列表
  laCustomersList: "/api/v1/laCustomers/list",
  // 获取班级岗位列表
  laStationsList: "/api/v1/laStations/list",
  // 提交基分创建
  laDaysCustomers: "/api/v1/laDaysCustomers",
};
